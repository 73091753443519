<template>
  <div class="card form-card checkout collapse show" v-if="!isLoading">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h5>{{ $t('forms.senderName.newSenderName') }}</h5>
      <button class="btn btn-sm btn-light" @click="backToSenderNameForm" v-if="showEditSenderNameButton">
        <i class="feather icon-edit-2 mx-1"></i>{{$t('edit')}}
      </button>
    </div>
    <div class="sender_name collapse show">
      <div class="card-block task-details ">
        <DynamicForm
          @submit="submitSenderName"
          :schema="senderNameFormSchema"
          :button-data="submitButton"
          :initial-field-value="getSenderNameInitialValue(formData)"
          :is-disabled="isErrorSenderName"
          @changeHandler="changeHandler"
          submitAlwaysEnabled
          form-class="justify-content-start align-items-start"
        ></DynamicForm>
      </div>
    </div>

    <div class="card-header d-flex justify-content-between align-items-center">
      <h5>{{ $t('forms.senderName.contactData') }}</h5>
      <button class="btn btn-sm btn-light" @click="backToContactDataForm" v-if="showEditContactDataButton">
        <i class="feather icon-edit-2 mx-1"></i>{{$t('edit')}}
      </button>
    </div>
    <div class="contact_data collapse">
      <div class="card-block task-details ">
        <DynamicForm
          @submit="submit"
          :schema="formSchema"
          :button-data="submitButton"
          :initial-field-value="getContactInfoInitialValue(formData)"
          @changeHandler="changeHandler"
          :isDisabled="isDisabled"
          submitAlwaysEnabled
          form-class="justify-content-start align-items-start"
        ></DynamicForm>
      </div>
    </div>

    <div class="card-header">
      <h5>{{ $t('forms.payment_title') }}</h5>
    </div>
    <div class="payment collapse">
      <div class="card-block task-details">
        <div class="d-flex justify-content-center">
          <div class="paymentDetail-info">
              <template v-if="price">
                <div class="paymentDetail-param paymentDetail-param_plan text-center" v-if="productName">{{productName}}</div>
                <hr>
                <div class="mt-1">
                  <div class="d-flex justify-content-between" v-if="localizedFee">
                    <span class="paymentDetail-param paymentDetail-param_main">{{formData.phoneNumberAlias === true ? $t('forms.senderName.senderNameSetupFee') : $t('forms.senderName.setupFee')}}:</span> <span class="paymentDetail-value paymentDetail-value_main">{{localizedFee}}</span>
                  </div>
                  <div class="d-flex justify-content-between mb-2" v-if="phoneNumberSetupLocalizedFee && formData.phoneNumberAlias === true">
                    <span class="paymentDetail-param paymentDetail-param_main">{{$t('forms.senderName.phoneSetupFee')}}:</span> <span class="paymentDetail-value paymentDetail-value_main">{{phoneNumberSetupLocalizedFee}}</span>
                  </div>
                  <div class="d-flex justify-content-between" v-if="price">
                    <span class="paymentDetail-param paymentDetail-param_main">{{$t('forms.senderName.monthlyFee')}}:</span> <span class="paymentDetail-value paymentDetail-value_main">{{price}} / mo.</span>
                  </div>
                  <div class="d-flex justify-content-between" v-if="phoneNumberLocalizedFee && formData.phoneNumberAlias === true">
                    <span class="paymentDetail-param paymentDetail-param_main">Phone number fee</span> <span class="paymentDetail-value paymentDetail-value_main">{{phoneNumberLocalizedFee}} / mo.</span>
                  </div>
                  <div class="d-flex justify-content-between" v-if="smsAndCallPriceLocalizedFee && formData.phoneNumberAlias === true && formData.smsAndCall === true">
                    <span class="paymentDetail-param paymentDetail-param_main">SMS and Call fee</span> <span class="paymentDetail-value paymentDetail-value_main">{{smsAndCallPriceLocalizedFee}} / mo.</span>
                  </div>
                  <hr v-if="plan.messages">
                  <div class="d-flex justify-content-between" v-if="plan.messages">
                    <span class="paymentDetail-param">{{ $t('forms.senderName.requestsPerMonth') }}:</span> <span class="paymentDetail-value">{{ plan.messages }}</span>
                  </div>
                  <div class="d-flex justify-content-between" v-if="plan.term">
                    <span class="paymentDetail-param">{{ $t('forms.senderName.requestsTerms') }}:</span> <span class="paymentDetail-value">{{ $t(`forms.buyPackages.terms.${plan.term}`) }}</span>
                  </div>
                  <div class="d-flex justify-content-between" v-if="afterProductString">
                    <span class="paymentDetail-param"> {{ $t('forms.senderName.secondMonthlyFee') }}:</span> <span class="paymentDetail-value">{{price}}</span>
                  </div>
                  <hr v-if="isAlreadyBilled === true">
                  <div class="d-flex justify-content-between" v-if="isAlreadyBilled === true">
                    <alert type="info" text="forms.billing.currentPaymentMethod" class="mb-1" />
                  </div>
                  <div class="d-flex justify-content-between" v-if="paymentMethod">
                    <span class="paymentDetail-param"> {{ $t('paymentMethodTab.paymentType') }}:</span> <span class="paymentDetail-value">{{ $t(`paymentMethod.${paymentMethod}`) }}</span>
                  </div>
                  <div class="d-flex justify-content-between" v-if="lastFourDigits">
                    <span class="paymentDetail-param"> {{ $t('paymentMethodTab.cardNumber') }}:</span> <span class="paymentDetail-value">*-{{lastFourDigits}}</span>
                  </div>
                  <div class="d-flex justify-content-between" v-if="expiryDate">
                    <span class="paymentDetail-param"> {{ $t('paymentMethodTab.expiry') }}:</span> <span class="paymentDetail-value">{{expiryDate}}</span>
                  </div>
                  <hr v-if="isAlreadyBilled === false && (vatRequired === true || gstRequired === true)">
                  <div class="pt-2 d-flex justify-content-between" v-if="isAlreadyBilled === false && vatRequired === true">
                    <alert type="info" text="forms.billing.vatDescription" class="mb-4" />
                  </div>
                  <div class="pt-2 d-flex justify-content-between" v-if="isAlreadyBilled === false && gstRequired === true">
                    <alert type="info"  text="forms.billing.gstDescription" class="mb-4" />
                  </div>
                </div>
              </template>
          </div>
        </div>
        <div class="d-flex justify-content-center py-1">
          <button
            class="btn btn-primary btn-lg shadow-2"
            :disabled="isDisabled"
            @click.prevent="letsPayHandler"
            style="position: relative"
          >
            <spinner-component v-if="isDisabled"></spinner-component>
            {{ $t('forms.buyPackages.buy') }}
          </button>
        </div>
        <div class="paymentDetail-info">
          <hr>
          <div class="pt-2 d-flex justify-content-between">
            <alert type="warning" text="forms.senderName.activationTerms" class="mb-1" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <spinner-component v-if="isLoading" item-class="table-spinner-layout"></spinner-component>
</template>

<script>

import { useSenderFormCreate } from '@/application/composables/formSenderName'
import DynamicForm from '@/application/components/forms/DynamicForm'
import { defineAsyncComponent, onMounted, ref, watch, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useModal } from '@/application/composables/modal'
import { getCurriedFormatPrice } from '@/application/utils/prices'
import { getLocalizedServerError } from '@/application/utils/localization'
import { initPaddle } from '@/application/utils/paddle'

export default {
  name: 'CheckoutPage',
  components: {
    DynamicForm,
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
    alert: defineAsyncComponent(() => import('@/application/components/elements/Alert.vue')),
  },
  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter()
    const i18n = useI18n()
    const {
      formSchema,
      senderNameFormSchema,
      submitButton,
      changeHandler,
      storedDataToFormData,
      phoneNumberLocalizedFeeString,
      phoneNumberSetupLocalizedFeeString,
      smsAndCallFeeString,
      getFormSchema,
      getSenderNameCheckout,
      finishSenderNameCheckout,
      getContactInfoInitialValue,
      getSenderNameInitialValue,
      formData,
      senderNameError,
    } = useSenderFormCreate(props.plan.plan_id)
    const isLoading = ref(true)
    const isDisabled = ref(false)
    const paddleIsLoading = ref(false)
    const paymentDone = ref(false)
    const showEditSenderNameButton = ref(false)
    const showEditContactDataButton = ref(false)
    const productName = ref('')
    const afterProductString = ref('')
    const price = ref('')
    const localizedFee = ref('')
    const phoneNumberLocalizedFee = ref('')
    const phoneNumberSetupLocalizedFee = ref('')
    const smsAndCallPriceLocalizedFee = ref('')
    const expiryDate = ref('')
    const lastFourDigits = ref('')
    const paymentMethod = ref('')
    const totalLocalizedPrice = ref('')
    const vat = ref(0)
    const total = ref(0)
    const contactData = ref({})
    const isAlreadyBilled = ref(false)
    const vatRequired = ref(false)
    const gstRequired = ref(false)

    onMounted(async() => {
      try {
        const { domains, localized_fee, localized_price, values, total_localized_price, bill_immediately, expiry_date, last_four_digits, payment_method, vat_required, gst_required, phone_number_localized_fee, sms_and_call_price_localized_fee, phone_number_setup_localized_fee } = await getSenderNameCheckout(props.plan.plan_id)
        isAlreadyBilled.value = bill_immediately

        if (localized_fee) {
          localizedFee.value = localized_fee
        }

        if (localized_price) {
          price.value = localized_price
        }

        if (phone_number_localized_fee) {
          phoneNumberLocalizedFee.value = phone_number_localized_fee
          phoneNumberLocalizedFeeString.value = phone_number_localized_fee
        }

        if (phone_number_setup_localized_fee) {
          phoneNumberSetupLocalizedFee.value = phone_number_setup_localized_fee
          phoneNumberSetupLocalizedFeeString.value = phone_number_setup_localized_fee
        }

        if (sms_and_call_price_localized_fee) {
          smsAndCallPriceLocalizedFee.value = sms_and_call_price_localized_fee
          smsAndCallFeeString.value = sms_and_call_price_localized_fee
        }

        if (total_localized_price) {
          totalLocalizedPrice.value = total_localized_price
        }

        if (expiry_date) {
          expiryDate.value = expiry_date
        }

        if (last_four_digits) {
          lastFourDigits.value = last_four_digits
        }

        if (payment_method) {
          paymentMethod.value = payment_method
        }

        if (vat_required) {
          vatRequired.value = vat_required
        }

        if (gst_required) {
          gstRequired.value = gst_required
        }

        if (values) {
          formData.value = storedDataToFormData(values)
        }
        senderNameFormSchema.value = getFormSchema(formSchema.value, domains)
        isLoading.value = false
        initPaddle(function(data) {
          const { prices } = data.eventData.checkout
          const { customer } = prices
          const formatPrice = getCurriedFormatPrice(i18n.locale.value, customer.currency)
          vat.value = customer.total_tax > 0 ? formatPrice(customer.total_tax) : 0
          total.value = customer.total > 0 ? formatPrice(customer.total) : 0
        })
      } catch (exception) {
        isLoading.value = false
        router.push({ name: 'not-found' })
      }
    })
    const isErrorSenderName = computed(() => senderNameError.value)
    watch(isErrorSenderName, (value) => {
      if (value) {
        setTimeout(backToSenderNameForm, 1500)
      }
    })

    const backToSenderNameForm = () => {
      if (!paymentDone.value) {
        showEditSenderNameButton.value = false
        window.$('.sender_name').collapse('show')
        window.$('.contact_data').collapse('hide')
        window.$('.payment').collapse('hide')
      }
    }

    const letsPayHandler = async() => {
      isDisabled.value = true
      const { openModal } = useModal()
      const { url, item_name } = await finishSenderNameCheckout(contactData.value)

      if (item_name) {
        productName.value = item_name
      }

      if (isAlreadyBilled.value) {
        isDisabled.value = false
        openModal(i18n.t('success'), i18n.t('forms.buyPackages.successMessage'), () => {
          router.push('/sender-name/show')
        })
      } else {
        paddleIsLoading.value = true
        try {
          isDisabled.value = false
          window.Paddle.Checkout.open({
            override: url,
            disableLogout: true,
            successCallback: function() {
              paymentDone.value = true
              openModal(i18n.t('success'), i18n.t('forms.buyPackages.successMessage'), () => {
                router.push('/sender-name/show')
              })
            },
          })
        } catch ({ response }) {
          isDisabled.value = false
          if (response.data && response.data.message) {
            openModal(i18n.t('error'), getLocalizedServerError(i18n, 'forms.senderName.errors.', response))
          }
          paddleIsLoading.value = false
        }
      }
    }

    return {
      isDisabled,
      senderNameFormSchema,
      formSchema,
      submitButton,
      changeHandler,
      formData,
      paddleIsLoading,
      showEditSenderNameButton,
      showEditContactDataButton,
      productName,
      afterProductString,
      lastFourDigits,
      expiryDate,
      paymentMethod,
      price,
      vat,
      total,
      localizedFee,
      phoneNumberLocalizedFee,
      phoneNumberSetupLocalizedFee,
      smsAndCallPriceLocalizedFee,
      totalLocalizedPrice,
      isAlreadyBilled,
      vatRequired,
      gstRequired,
      getContactInfoInitialValue,
      getSenderNameInitialValue,
      isErrorSenderName,
      backToSenderNameForm,
      backToContactDataForm: () => {
        if (!paymentDone.value) {
          showEditContactDataButton.value = false
          showEditSenderNameButton.value = true
          window.$('.sender_name').collapse('hide')
          window.$('.contact_data').collapse('show')
          window.$('.payment').collapse('hide')
        }
      },
      submitSenderName: () => {
        showEditSenderNameButton.value = true
        window.$('.sender_name').collapse('hide')
        window.$('.contact_data').collapse('show')
      },
      submit: async(data) => {
        contactData.value = data
        window.$('.sender_name').collapse('hide')
        window.$('.contact_data').collapse('hide')
        window.$('.payment').collapse('show')
      },
      letsPayHandler,
      isLoading,
    }
  },
}
</script>
