import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useModal } from '@/application/composables/modal'
import { getSenderNameCheckout, storeSenderNameCheckout, finishSenderNameCheckout } from '@/application/services/sender-name'
import { LOWER_CASE_FILTER } from '@/application/composables/filters'
import { getLocalizedServerError } from '@/application/utils/localization'

export const useSenderFormCreate = (planId) => {
  const i18n = useI18n()
  const { openModal } = useModal()
  const senderDomains = ref([])
  const rawSenderDomains = ref([])
  const descriptionDomain = ref(null)
  const formData = ref({})
  const senderNameError = ref(false)
  const phoneNumberLocalizedFeeString = ref(null)
  const phoneNumberSetupLocalizedFeeString = ref(null)
  const smsAndCallFeeString = ref(null)
  const senderNameFormSchema = computed(() => ({
    senderName: {
      label: i18n.t('forms.checkout.senderName'),
      name: 'senderName',
      type: 'text',
      as: 'input',
      rules: 'required|sender_name_frontend_minimal',
      cols: 'col-12 col-sm-8',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
      description: i18n.t('forms.senderName.fieldHint'),
      filter: LOWER_CASE_FILTER,
    },
    senderDomain: {
      label: i18n.t('forms.checkout.senderDomain'),
      name: 'senderDomain',
      type: 'text',
      as: 'select',
      rules: 'required',
      cols: 'col-12 col-sm-4',
      inputClass: 'form-control',
      fieldClass: 'form-group senderName',
      value: '',
      validateOnInput: false,
      children: senderDomains.value,
      description: descriptionDomain,
      isHTMLDescription: true,
    },
    phoneNumberAlias: {
      htmlLabel: `${i18n.t('forms.checkout.phoneNumberAlias')} <b>+${phoneNumberLocalizedFeeString.value} ${phoneNumberSetupLocalizedFeeString.value ? 'and once-off setup fee' : ''} </b>`,
      name: 'phoneNumberAlias',
      type: 'checkbox',
      as: 'input',
      validateOnMount: false,
      cols: 'col-12',
      labelClass: 'cr',
      fieldClass: 'checkbox checkbox-primary d-inline',
      description: i18n.t('forms.checkout.phoneNumberAliasDescription'),
    },
    smsAndCall: {
      htmlLabel: `${i18n.t('forms.checkout.smsAndCall')} <b>+${smsAndCallFeeString.value}</b>`,
      name: 'smsAndCall',
      type: 'checkbox',
      as: 'input',
      validateOnMount: false,
      rules: '',
      cols: `col-12 ${formData.value.phoneNumberAlias ? 'd-inline' : 'hide-form-column'}`,
      labelClass: 'cr',
      fieldClass: 'checkbox checkbox-primary',
      description: i18n.t('forms.checkout.smsAndCallDescription'),
      isHTMLDescription: true,
    },
    phoneNumberAliasZipCode: {
      label: i18n.t('forms.checkout.phoneNumberAliasZipCode'),
      name: 'phoneNumberAliasZipCode',
      as: 'input',
      type: 'text',
      rules: `${formData.value.phoneNumberAlias ? 'max:10|min:5' : ''}`,
      cols: `col-12 ${formData.value.phoneNumberAlias ? '' : 'hide-form-column'}`,
      description: i18n.t('forms.checkout.phoneNumberAliasZipCodeDescription'),
      value: '',
      inputClass: 'form-control',
    },
  }))

  const formSchema = computed(() => ({
    description: {
      customHtml: i18n.t('forms.senderName.contactDataDescription'),
      inputClass: 'form-control',
      fieldClass: 'form-group',
      cols: 'col-12',
      isHTMLDescription: true,
    },
    email: {
      label: i18n.t('forms.checkout.email'),
      name: 'email',
      type: 'text',
      as: 'input',
      rules: 'required|email|disposable_email',
      cols: 'col-12',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
    },
    name: {
      label: i18n.t('forms.checkout.name'),
      name: 'name',
      type: 'text',
      as: 'input',
      rules: 'required',
      cols: 'col-12 col-sm-6',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
    },
    lastName: {
      label: i18n.t('forms.checkout.last_name'),
      name: 'lastName',
      type: 'text',
      as: 'input',
      rules: 'required',
      cols: 'col-12 col-sm-6',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
    },
    phoneNumber: {
      label: i18n.t('phoneNumber'),
      name: 'phoneNumber',
      type: 'text',
      as: 'input',
      rules: 'required|phone_mobile',
      cols: 'col-12',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
      description: i18n.t('forms.senderName.phoneNumberDescription'),
    },
    companyName: {
      label: i18n.t('forms.checkout.companyName'),
      name: 'companyName',
      type: 'text',
      as: 'input',
      rules: 'required',
      cols: 'col-12',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
      description: i18n.t('forms.senderName.companyNameDescription'),
    },
    contactUrl: {
      label: i18n.t('forms.senderName.contactUrl'),
      name: 'contactUrl',
      type: 'text',
      as: 'input',
      rules: 'required|url:false',
      cols: 'col-12',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
      description: i18n.t('forms.senderName.contactUrlDescription'),
    },
    agreement: {
      htmlLabel: i18n.t('termsRulesAgreementSenderName'),
      name: 'agreement',
      type: 'checkbox',
      as: 'input',
      rules: 'required',
      validateOnMount: false,
      cols: 'col-12',
      labelClass: 'cr',
      fieldClass: 'checkbox checkbox-primary d-inline',
    },
  }))

  const submitButton = computed(() => ({
    wrapper: 'justify-content-start',
    class: 'btn btn-primary shadow-2 mt-4',
    text: i18n.t('continue'),
  }))

  const changeHandler = (name, values, errors) => {
    senderNameError.value = false
    formData.value = {
      ...formData.value,
      ...values,
    }
    formData.value.senderName = formData.value.senderName.toLowerCase()

    const { senderDomain, senderName } = formData.value
    if (senderDomain) {
      const selectedDomain = rawSenderDomains.value.find((domain) => domain.domain === senderDomain)
      if (selectedDomain && selectedDomain.type === 'otp') {
        descriptionDomain.value = i18n.t('forms.senderName.domainOtpDescription')
      } else {
        if (descriptionDomain.value) {
          descriptionDomain.value = null
        }
      }
    }
    if (senderName && senderDomain) {
      storeSenderNameCheckout(formDataToStoreData(formData.value), planId).catch(
        err => {
          senderNameError.value = true
          const response = err.response
          openModal(i18n.t('error'), getLocalizedServerError(i18n, 'forms.senderName.errors.', response))
        }
      )
    }
  }

  const storedDataToFormData = (storedData) => {
    return {
      email: storedData.email,
      name: storedData.first_name,
      lastName: storedData.last_name,
      phoneNumber: storedData.phone_number,
      companyName: storedData.company,
      senderName: storedData.name,
      senderDomain: storedData.domain,
      contactUrl: storedData.form,
      phoneNumberAlias: storedData.phone_number_alias,
      phoneNumberAliasZipCode: storedData.phone_number_alias_zip_code,
      smsAndCall: storedData.sms_and_call,
    }
  }

  const formDataToStoreData = (formData) => {
    const dataToStore = {
      email: formData.email,
      first_name: formData.name,
      last_name: formData.lastName,
      phone_number: formData.phoneNumber,
      company_name: formData.companyName,
      form: formData.contactUrl,
      name: formData.senderName,
      domain: formData.senderDomain,
      phone_number_alias: formData.phoneNumberAlias,
      phone_number_alias_zip_code: formData.phoneNumberAliasZipCode,
      sms_and_call: formData.smsAndCall,
    }
    for (const key in dataToStore) {
      if (!dataToStore[key]) {
        delete dataToStore[key]
      }
    }

    return dataToStore
  }

  const getFormSchema = (formSchema, domains) => {
    rawSenderDomains.value = [...domains]
    senderDomains.value = domains.map(domain => ({
      tag: 'option',
      text: domain.domain,
      value: domain.domain,
    }))
    return formSchema
  }

  const getSenderNameInitialValue = (formData) => ({ senderName: formData.senderName, senderDomain: formData.senderDomain, phoneNumberAlias: formData.phoneNumberAlias, smsAndCall: formData.smsAndCall, phoneNumberAliasZipCode: formData.phoneNumberAliasZipCode })
  const getContactInfoInitialValue = (formData) => {
    const data = { ...formData }
    delete data.senderName
    delete data.senderDomain
    delete data.phoneNumberAlias
    delete data.phoneNumberAliasZipCode
    delete data.smsAndCall
    return data
  }
  return {
    formData,
    formSchema,
    senderNameFormSchema,
    i18n,
    submitButton,
    senderNameError,
    changeHandler,
    storedDataToFormData,
    formDataToStoreData,
    getFormSchema,
    phoneNumberLocalizedFeeString,
    phoneNumberSetupLocalizedFeeString,
    smsAndCallFeeString,
    getSenderNameCheckout: () => getSenderNameCheckout(planId).then((response) => response.data),
    finishSenderNameCheckout: (data) => finishSenderNameCheckout(formDataToStoreData({
      ...formData.value,
      ...data,
    }), planId).then((response) => response.data),
    getSenderNameInitialValue,
    getContactInfoInitialValue,
  }
}
