export const formatPrice = (locale, currency, price) => {
  return new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(price)
}

export const formatRecurring = (locale, currency, period, periodValue, price) => {
  const formattedPrice = formatPrice(locale, currency, price)
  if (periodValue > 1) {
    return formattedPrice + ' / ' + periodValue + ' ' + period
  } else {
    return formattedPrice + ' / ' + period
  }
}

export const getCurriedFormatPrice = (locale, currency) => {
  return formatPrice.bind(null, locale, currency)
}

export const getCurriedFormatRecurring = (locale, currency, period, periodValue) => {
  return formatRecurring.bind(null, locale, currency, period, periodValue)
}
